import * as React from "react";
import { Constants } from "../../@types/Constants";
import ErrorMessageAlert from "../../components/common/ErrorMessageAlert/ErrorMessageAlert";
import { disclaimer } from "./TravelPassWelcome.module.scss";

export const Disclaimer: React.FC<{
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}> = (props) => {
  return <div className={`${disclaimer} mt-3 d-block`}>{props.children}</div>;
};

export const TrackErrorMessage: React.FC<{ message: string; field: string }> = (
  props
) => {
  const trackMessage =
    props.message && typeof props.message !== undefined
      ? `${props.message} - ${props.field}`
      : "";
  return (
    <ErrorMessageAlert
      errorType={Constants.ERRORSTYPE.TRAVELPASS}
      errorSubType={Constants.ERRORSSUBTYPE.TRAVELPASS.PROFILEACTIVATION}
      message={trackMessage}
    >
      {props.message}
    </ErrorMessageAlert>
  );
};
